<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <v-container class="pa-0">
      <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            From User
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            To User
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="2">
          <s-text weight="medium" color="gray" size="sm">
            Duration
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            Date Sent
          </s-text>
        </v-col>
      </v-row>
      <v-row
        v-for="call in calls"
        :key="call.ID"
        class="pa-0 ma-0 mb-3 app-card app-card-content"
        @click="openTransactionDetails(call)"
      >
        <v-col class="d-flex flex-column" cols="3" v-for="(_, $index) in [0,1]" :key="`${call.ID}-${$index}-${call.Participants[$index]}`">
          <s-link
            weight="medium"
            color="grayTextAlt"
            v-if="call.Participants[$index]"
            @click.prevent
            :to="{ name: 'appDetails', params: { id: call.Participants[$index] } }"
          >
            {{ call.Participants[$index] }}
          </s-link>
          <div v-else>-</div>
        </v-col>
        <v-col class="d-flex flex-column" cols="2">
          <div>
            {{ call.Duration }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <div>
            {{ call.CreatedAt ? getHumanReadableDateShort(call.CreatedAt) : "-" }}
          </div>
        </v-col>
        <v-col class="action_group d-flex align-center flex-row-reverse" cols="1">
          <s-icon name="arrow-circle-right" class="mr-2" />
        </v-col>
      </v-row>
    </v-container>
    <s-dialog
      v-model="showLogDetailsModal"
      persistent
      isModal
      title="Message Details"
      :close-default="false"
      @close="showLogDetailsModal = false;"
    >
      <view-call-details
        v-if="showLogDetailsModal"
        :call="call"
        @close="showLogDetailsModal = false"
      />
    </s-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewCallDetails from '@/components/transaction/ViewCallDetails'
import Loader from '@/components/cards/Loader'
import { getHumanReadableDateShort } from '@/utils/date'
export default {
  name: 'Calls',
  components: {
    loader: Loader,
    'view-call-details': ViewCallDetails,
  },
  props: {
    txnId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      calls: 'txns/calls',
    }),
  },
  data () {
    return {
      isLoading: false,
      call: null,
      showLogDetailsModal: false,
    }
  },
  methods: {
    getHumanReadableDateShort,
    getCalls () {
      this.isLoading = true
      this.$store
        .dispatch('txns/getCalls')
        .finally(() => {
          this.isLoading = false
        })
    },
    openTransactionDetails (call) {
      this.showLogDetailsModal = true
      this.call = call
    },
  },
  watch: {
    calls: {
      handler () {
        if (this.calls) return
        this.getCalls()
      },
      immediate: true,
    },
  },
}
</script>
