<template>
  <div class="px-9 my-5">
    <loader
      v-if="isLoading"
      message="Loading ...."
    />
    <div v-else>
      <v-list-item-group class="mb-5">
        <v-list-item-content class="py-4 pl-0 top-thin-border">
          <v-col class="pl-0">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Call ID</s-text>
            <s-text weight="bold" size="md" color="black">
              {{ call.CallID || 'Unknown' }}
            </s-text>
          </v-col>
        </v-list-item-content>
        <v-list-item-content class="py-4 pl-0 top-thin-border">
          <v-col class="pl-0">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Start</s-text>
            <s-text weight="bold" size="md" color="black">
              {{ call.StartedAt ? getHumanReadableDateShort(call.StartedAt) : "-" }}
            </s-text>
          </v-col>
          <v-col class="pl-0">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">End</s-text>
            <s-text weight="bold" size="md" color="black">
              {{ call.EndedAt ? getHumanReadableDateShort(call.EndedAt) : "-" }}
            </s-text>
          </v-col>
        </v-list-item-content>
        <v-list-item-content class="py-4 pl-0 top-thin-border">
          <v-col class="pl-0" v-for="(_, $index) in [0,1]" :key="`${call.CallID}-${call.Participants[$index]}`">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Participants</s-text>
            <s-link
              weight="medium"
              color="grayTextAlt"
              :to="{ name: 'appDetails', params: { id: call.Participants[$index] } }"
            >
              {{ call.Participants[$index] || "-" }}
            </s-link>
          </v-col>
        </v-list-item-content>
        <v-list-item-content :ripple="false" class="top-thin-border">
          <v-col class="pl-0">
            <s-text
              weight="normal"
              size="sm"
              color="grayTextAlt"
              class="mb-4"
            >
              Duration
            </s-text>
            <s-text size="md-m" weight="bold">
              {{ call.Duration }}
            </s-text>
          </v-col>
        </v-list-item-content>
        <v-list-item-content :ripple="false" class="top-thin-border">
          <v-col class="pl-0">
            <s-text
              weight="normal"
              size="sm"
              color="grayTextAlt"
              class="mb-4"
            >
              Is ongoing
            </s-text>
            <s-text size="md-m" weight="bold">
              {{ call.Ongoing }}
            </s-text>
          </v-col>
        </v-list-item-content>
        <v-list-item-content :ripple="false" class="top-thin-border">
          <v-col class="pl-0">
            <s-text
              weight="normal"
              size="sm"
              color="grayTextAlt"
              class="mb-4"
            >
              Created At
            </s-text>
            <s-text size="md-m" weight="bold">
              {{ call.CreatedAt ? getHumanReadableDateShort(call.CreatedAt) : "-" }}
            </s-text>
          </v-col>
        </v-list-item-content>
      </v-list-item-group>
      <s-btn
        @click="goBack"
        type="button"
        color="primary"
        elevation="0"
      >
        OK
      </s-btn>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/cards/Loader'
import { getHumanReadableDateShort } from '@/utils/date'
export default {
  name: 'ViewCallDetails',
  components: {
    loader: Loader,
  },
  props: {
    call: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isLoading: false,
    }
  },
  methods: {
    getHumanReadableDateShort,
    getCurrencyFormatted (unit) {
      return (unit / 100).toFixed(2)
    },
    goBack () {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  border-radius: 6px;
  color: var(--v-gray-base);
  font-size: 14px;
}
</style>
